var stickyHeader = true;
var transformHomepageDonationAmountImages = true;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 3000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
var feedTitleLinks = ['/Listing/Category/news-and-blogs', '/Listing/Category/our-stories'];
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

var lualBodyClasses = ["christmas-appeal"];


// Header layout - Header search
$(".headerContent .header-search").appendTo(".headerContent .mainCallToAction");


// Card - No banner
$(".homeFeatures .homeFeature:not(:has(.banner))").addClass("homeFeatureNoBanner");
$(".feedItem:not(:has(.banner))").addClass("feedItemNoBanner");
$(".listedPost:not(:has(.banner))").addClass("listedPostNoBanner");


// Placeholder for map search input
if ($('body').hasClass('pageHasGoogleMap')) {
  $('input.postcodeSearch').prop('placeholder', 'Enter postcode');
}


// Donation amount Other - Placeholder
$(".formDonatePage #donationAmountOther").attr('placeholder', '£');


// Recenter and zoom map on /find-an-advisor
$(window).bind("load", function () {
  CenterMap(new google.maps.LatLng(56.810497, -4.180032), 7);
});


// Footer - Logos
$(".footerBox[class*='ogos']").appendTo(".pageFooterWrapper");


// Events - External booking
$(".eventsPostBody.PostCategory_event-sign-up-today .eventBookingBtn").text("Sign up today");


// Run functions responsively
$(document).ready(function () {
  subMenuHeight();
  // headerBackground();
  $(window).resize(function() {
    subMenuHeight();
    // headerBackground();
  });
});

// Submenu max-height
function subMenuHeight() {
  if ($(window).width() >= 1201) {	
    var headerHeight = $('.pageHeader').outerHeight();
    $('.subMenu').css({'max-height' : 'calc(100vh - ' + headerHeight + 'px - 5px) ' });
  };
};

// Header Background
// function headerBackground() {
//   var headerHeight = $('.pageHeader').outerHeight();
//   var adminBarHeight  =  $('.adminBar').outerHeight();
//   $('.pageHeader').css({'background-size' : 'auto calc( ' + headerHeight + 'px - ' + adminBarHeight + 'px )' })
// }